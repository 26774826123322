import axios from "axios";
import { services } from "helpers/vars";

const urlApi = `${services.api.server}`;

// Axios Instances

const serviceApi = axios.create({
  baseURL: urlApi,
  responseType: "json",
})

export { serviceApi };
