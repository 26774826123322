import { postCambiarEstadoUsuario } from "api/users.routes"
import Swal from "sweetalert2"

export const cambiarEstado = (id, estado, setUsers) => {
    postCambiarEstadoUsuario(id, estado)
    .then(() => {
        setUsers((prevState) => prevState.filter((user) => user.id !== id))
        Swal.fire({
            icon:'success',
            title:`Usuario correctamente ${estado === 'S' ? "habilitado" : "deshabilitado"}`,
            timer:2000
        })
    })
    .catch(() => {
        Swal.fire({
            icon:'error',
            title:`Error al intentar ${estado === 'S' ? "habilitar" : "deshabilitar"} al usuario`,
            text:"Intente nuevamente mas tarde"
        })
    })
}