import {
  Box,
  Chip,
  FormControl,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { FilterLocalidades } from "components/Filters/FilterLocalidades";
import MDButton from "components/MDButton";
import { NoData } from "components/NoData/NoData";
import { LocalidadesContext } from "context/LocalidadesProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { downloadExcel } from "helpers/downloadExcel";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { reportsFiltersTable } from "./reportsFiltersTable";
import { postSupervisorsReportsFiltered } from "api/supervisores.routes";
import { familiesReportTable } from "./familiesReportTable";
import "../../styles/tableStyle.css";
import { useNavigate } from "react-router-dom";
import { getFiltersReportsAsper, getFiltersReportsOvi } from "api/users.routes";
import Cargando from "pages/Cargando";
import formatResponse from "helpers/formatResponse";
import { Export } from "components/Buttons/Export";
import { postMuestrasOvitrampasFiltrosVector } from "api/reports.routes";
import { vectorTable } from "./vectorReportTable";
import Map from "components/Map/Map";

const programas = [
  {
    id: 1,
    name: "Ovitrampa",
    cantidadPreguntasFam: 4,
    cantidadPreguntasSup: 4,
  },
  {
    id: 2,
    name: "Aspersor",
    cantidadPreguntasFam: 2,
    cantidadPreguntasSup: 4,
  },
];

export const Reportes = () => {
  const [programa, setPrograma] = useState(programas[0]);
  const [fechaDesde, setFechaDesde] = useState();
  const [fechaHasta, setFechaHasta] = useState();
  const [data, setData] = useState();
  const [checked, setChecked] = useState("vector");
  const [isLoading, setIsLoading] = useState(false);
  const { localidad } = useContext(LocalidadesContext);
  const navigate = useNavigate();

  const handleChange = (e, a) => {
    setPrograma(programas[a.props.value - 1]);
  };

  const addFamiliesColumnTitles = (table, questions, type) => {
    const tableWithTitles = table.map((column, index) => {
      if (column.name === "Imagen") {
        return {
          ...column,
          selector: (row) => (
            <Chip
              label={"Ver imagen"}
              variant="outlined"
              clickable={true}
              onClick={() => window.open(`${row.imagen.props.src}`, "_blank")}
            />
          ),
        };
      }
      if (column.name === "Codigo referente") {
        return {
          ...column,
          selector: (row) => (
            <Chip
              label={row.sup_dni}
              variant="outlined"
              clickable={true}
              onClick={() => navigate(`/supervisores/reportes/${row.sup_id}`)}
            />
          ),
        };
      }
      if (column.name === "Codigo dispositivo") {
        return {
          ...column,
          selector: (row) => (
            <Chip
              label={row.ovitrampa_codigo || row.aspersor_codigo}
              variant="outlined"
              clickable={true}
              onClick={() =>
                navigate(
                  `/familias/${row.ovitrampa_codigo ? "ovitrampa" : "aspersor"}/reportes/${
                    row.familia_id
                  }`
                )
              }
            />
          ),
        };
      }
      if (column.name === "DNI") {
        return {
          ...column,
          selector: (row) => (
            <Chip
              label={row.familia_dni}
              variant="outlined"
              clickable={true}
              onClick={() =>
                navigate(
                  `/familias/${row.ovitrampa_codigo ? "ovitrampa" : "aspersor"}/reportes/${
                    row.familia_id
                  }`
                )
              }
            />
          ),
        };
      }
      return column;
    });

    if (type === "familia") {
      for (let i = 0; i < programa.cantidadPreguntasFam; i++) {
        if (questions[`preg${i + 1}`] !== null) {
          const question = {
            name: questions[`preg${i + 1}`],
            selector: (row) => formatResponse(row[`res${i + 1}`]),
            sortable: true,
            width: "200px",
          };

          tableWithTitles.splice(i + 2, 0, question);
        }
      }
    } else {
      for (let i = 0; i < programa.cantidadPreguntasSup; i++) {
        const question = {
          name: questions[`preg${i + 1}`],
          selector: (row) => formatResponse(row[`res${i + 1}`]),
          sortable: true,
          width: "200px",
        };

        tableWithTitles.splice(i + 2, 0, question);
      }
    }

    return tableWithTitles;
  };

  const formatAddImage = (data) => {
    return data?.map((obj) => {
      obj["imagen"] = <img src={`${obj.imagen}`} width={"100%"} />;
      return obj;
    });
  };

  const handleFilter = () => {
    const filters = {
      id_localidad: localidad.id,
      fecha_desde: fechaDesde,
      fecha_hasta: fechaHasta,
    };
    setIsLoading(true);
    switch(checked){
      case "familia":
        if (programa.id === 1) {
          getFiltersReportsOvi(filters)
            .then((data) => {
              setData(formatAddImage(data.data));
            })
            .finally(() => setIsLoading(false));
        } else {
          getFiltersReportsAsper(filters)
            .then((data) => {
              setData(formatAddImage(data.data));
            })
            .finally(() => setIsLoading(false));
        }
        break;
      case "supervisor":
        postSupervisorsReportsFiltered(filters)
        .then((data) => {
          setData(formatAddImage(data.data));
        })
        .finally(() => setIsLoading(false));
        break;
      case "vector":
        postMuestrasOvitrampasFiltrosVector({
          ...filters,
          "tipo": 1,
        }).then(({data}) => {
          setData(data);
        })
        .finally(() => setIsLoading(false));
        break
    }
  };

  const handleToggle = (value) => () => {
    if (checked !== value) {
      setChecked(value);
    }
  };

  useEffect(() => {
    setData(null);
  }, [programa, checked]);

  const actionsMemo = <Export onExport={() => downloadExcel(data)} />;
  if (isLoading) return <Cargando />;
  return (
    <DashboardLayout>
      <Box marginY={4}>
        <FilterLocalidades>
          <Box width={"200px"}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Programa</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={programa.id}
                label={programa.name}
                onChange={handleChange}
                style={{
                  height: "2rem",
                }}
              >
                {programas?.map((programa) => {
                  return (
                    <MenuItem key={programa.id} value={programa.id}>
                      {programa.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box display={"flex"}>
            <Box>
              {" "}
              <Typography>Desde</Typography>{" "}
              <Input type="date" onChange={(e) => setFechaDesde(e.target.value)} />{" "}
            </Box>
            <Box>
              {" "}
              <Typography>Hasta</Typography>{" "}
              <Input type="date" onChange={(e) => setFechaHasta(e.target.value)} />{" "}
            </Box>
          </Box>
          <MDButton color="info" onClick={handleFilter}>
            Filtrar
          </MDButton>
        </FilterLocalidades>
      </Box>
      <List
        sx={{ width: "100%", maxWidth: 360 }}
        subheader={<Typography>Filtrar reportes por: </Typography>}
      >
        <ListItem>
          <ListItemText id="switch-list-label-familia" primary="Familia" />
          <Switch
            edge="end"
            onChange={handleToggle("familia")}
            checked={checked.indexOf("familia") !== -1}
            inputProps={{
              "aria-labelledby": "switch-list-label-familia",
            }}
          />
        </ListItem>
        <ListItem>
          <ListItemText id="switch-list-label-supervisor" primary="Referente" />
          <Switch
            edge="end"
            onChange={handleToggle("supervisor")}
            checked={checked.indexOf("supervisor") !== -1}
            inputProps={{
              "aria-labelledby": "switch-list-label-supervisor",
            }}
          />
        </ListItem>
        <ListItem>
          <ListItemText id="switch-list-label-vector" primary="Presencia del vector" />
          <Switch
            edge="end"
            onChange={handleToggle("vector")}
            checked={checked.indexOf("vector") !== -1}
            inputProps={{
              "aria-labelledby": "switch-list-label-vector",
            }}
          />
        </ListItem>
      </List>
      {
        (checked === "vector" && data?.length > 0) && <Map data={data} pinType={"vector"} isLoading={isLoading}/>
      }
      {data?.length > 0 ? (
        <DataTable
          columns={
            checked === "familia"
              ? addFamiliesColumnTitles(familiesReportTable, data[0], checked)
              : checked === "supervisor" 
              ? addFamiliesColumnTitles(reportsFiltersTable, data[0], checked)
              : checked === "vector" && vectorTable
          }
          dense={true}
          data={data}
          actions={actionsMemo}
          pagination
          defaultSortFieldId={"date"}
          defaultSortAsc={false}
        />
      ) : (
        <NoData />
      )}
    </DashboardLayout>
  );
};
