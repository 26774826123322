import { Box } from "@mui/material";
import { NoData } from "components/NoData/NoData";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Cargando from "pages/Cargando";
import DataTable from "react-data-table-component";
import { newUserTable } from "./newUsersTable";
import { useGetNewUsers } from "./useGetNewUsers";
import "../../styles/tableStyle.css";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { FilterComponent } from "components/Filters/FilterComponent";

function NewUsersPanel() {
  //TODO: CUSTOM HOOK PROVISORIO HASTA QUE JP CREE EL ENDPOINT. CAMBIAR TABLA.
  const { users, isLoading, addActionsButtons } = useGetNewUsers();
  const navigate = useNavigate();

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredUsers = users.filter(
    item => {
      for (let key in item) {
        if(key !== 'id'){
          if (item[key] && (item[key].toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1)) {
            return item;
          }
        }
      }
    }
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  if (isLoading) return <Cargando />;
  return (
    <DashboardLayout>
      <Box marginY={4} display={"flex"} width={"100%"} justifyContent={"flex-end"}>
        <MDButton color="success" onClick={() => navigate("/admins/register")}>
          + Agregar usuario
        </MDButton>
      </Box>
      {users.length > 0 ? (
        <DataTable columns={newUserTable} data={addActionsButtons(filteredUsers)}
        subHeader
        subHeaderComponent={subHeaderComponentMemo} 
        pagination
          />
      ) : (
        <NoData />
      )}
    </DashboardLayout>
  );
}

export default NewUsersPanel;
