import { createContext, useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const AuthCotext = createContext({});

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    isAuth:false
  });

  const navigate = useNavigate()

  useEffect(() => {
    if(!localStorage.getItem("isAuth")){
      navigate("/authentication/sign-in")
    }
  }, [])

  return <AuthCotext.Provider value={{auth, setAuth}}>{children}</AuthCotext.Provider>;
}
AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
export default AuthCotext;
