import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { FormEditUsers } from "components/Forms/FormEditUsers";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useFormEditUsers } from "hooks/useFormEditUsers";
import Error404 from "pages/404";
import Cargando from "pages/Cargando";
import { useNavigate, useParams } from "react-router-dom";

export const FormDataFamily = () => {
  const {
    localidades,
    localidad,
    register,
    handleSubmit,
    errors,
    handleLocalidad,
    onSubmit,
    isLoading,
    familyData,
  } = useFormEditUsers();
  const navigate = useNavigate()
  const params = useParams();

  if (isLoading) return <Cargando />;
  if (!familyData) return <Error404 />;
  return (
    <DashboardLayout>
      <h1>Datos de familia aliada</h1>
      <FormEditUsers
        localidades={localidades}
        localidad={localidad}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        handleLocalidad={handleLocalidad}
        onSubmit={onSubmit}
        familyData={familyData}
      >
        <Box marginY={2} marginX={2}>
          <Grid item xs={12}>
            <MDInput
              type="text"
              label="Cantidad de integrantes"
              defaultValue={familyData?.cantidad}
              {...register("cantidad", {
                required: true,
                min: 1,
                pattern: /^[0-9]+$/i,
              })}
            />
            {errors.apellido?.type === "required" && (
              <MDTypography color="error">Cantidad de integrantes es obligatorio</MDTypography>
            )}
            {errors.apellido?.type === "pattern" && (
              <MDTypography color="error">Caracteres invalidos, solo acepta numeros</MDTypography>
            )}
          </Grid>
          <Grid item xs={12}>
            <p>Programa</p>
            <FormControlLabel
              control={
                <Checkbox {...register("ovitrampa")} defaultChecked={familyData?.ovitrampa} />
              }
              label="Ovitrampa"
            />
            <p>{familyData?.codigo_ovitrampa}</p>
            <Box marginY={2}>
              <Grid item xs={12}>
                <MDButton color="info" onClick={() => navigate(`/changeOvitrampa/${familyData.dni}/${familyData.codigo_ovitrampa}`)}>
                  Cambiar Código Ovitrampa
                </MDButton>
              </Grid>
            </Box>
            <FormControlLabel
              control={<Checkbox {...register("kit")} defaultChecked={familyData?.kit} />}
              label="Aspersor"
            />
            <p>{familyData?.codigo_aspersor}</p>
          </Grid>
        </Box>
      </FormEditUsers>
    </DashboardLayout>
  );
};
