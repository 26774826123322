// @mui material components
import { ThemeProvider } from "@mui/material/styles";
// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React routes
import { Route, Routes } from "react-router-dom";
import Layout from "layouts/Layout";

import routes from "routes/routes";
import PrivateRoute from "routes/PrivateRoutes";
import Error404 from "pages/404";
import setUpInterceptor from "api/interceptor";
import { LocalidadesProvider } from "context/LocalidadesProvider";

export default function App() {
  setUpInterceptor();
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Layout />}>
          {routes.public.map(({ route, component: Component, name }) => (
            <Route path={route} element={<Component />} key={name} />
          ))}
          {routes.private.map(({ route, component: Component, name, roles }) => (
            <Route
              path={route}
              key={name}
              element={
                <PrivateRoute authRole={roles}>
                  <LocalidadesProvider>
                    <Component />
                  </LocalidadesProvider>
                </PrivateRoute>
              }
            />
          ))}
          <Route path={"/*"} element={<Error404 />} key="Error 404" />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}
