import InfoLayout from "layouts/DisplayInfo";

function Cargando() {
  return (
    <InfoLayout>
        <h1>Cargando...</h1>
    </InfoLayout>
  );
}

export default Cargando;
