import MDButton from 'components/MDButton'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const Return = () => {
    const navigate = useNavigate()
  return (
    <MDButton color="info" onClick={() => navigate(-1)}>Volver</MDButton>
  )
}
