import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import Error404 from "pages/404";

function PrivateRoute({ children, authRole }) {
  if (localStorage.getItem("isAuth") === "true") {
    if (authRole.includes(localStorage.getItem("role"))) {
      return children;
    }
    return <Error404 errorMessage="No esta autorizado para realizar esta acción"/>;
  }
  return <Navigate to="/authentication/sign-in" replace />;
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  authRole: PropTypes.arrayOf("strings").isRequired,
};

export default PrivateRoute;
