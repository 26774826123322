import { Box } from "@mui/material";
import { FilterLocalidades } from "components/Filters/FilterLocalidades";
import { NoData } from "components/NoData/NoData";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Cargando from "pages/Cargando";
import DataTable from "react-data-table-component";
import { newUserTable } from "./newUsersTable";
import { useGetNewUsers } from "./useGetNewUsers";
import "../../styles/tableStyle.css";

function NewUsers() {
  const { users, isLoading, addActionsButtons } = useGetNewUsers();

  if (isLoading) return <Cargando />;
  return (
    <DashboardLayout>
      <Box marginY={4}>
        <FilterLocalidades />
      </Box>
      {users.length > 0 ? (
        <DataTable columns={newUserTable} data={addActionsButtons(users)} pagination />
      ) : (
        <NoData />
      )}
    </DashboardLayout>
  );
}

export default NewUsers;
