import { getMuestrasAspersorById, getMuestrasById } from "api/muestras.routes";
import { getSupervisorsReports } from "api/supervisores.routes";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";

export const useGetReports = () => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    if (location.pathname.includes("supervisores")) {
      getSupervisorsReports(params.id)
        .then(({ data }) => {
          if (data) {
            setData(formatAddImage(data));
          }
        })
        .catch((e) =>
          Swal.fire({
            icon: "error",
            title: "error al obtener datos del id",
            text: e,
          })
        )
        .finally(() => setIsLoading(false));
    } else {
      if (location.pathname.includes("ovitrampa")) {
        getMuestrasById(params.id)
          .then(({ data }) => {
            if (data) {
              setData(formatAddImage(data));
            }
          })
          .catch((e) =>
            Swal.fire({
              icon: "error",
              title: "error al obtener datos del id",
              text: e,
            })
          )
          .finally(() => setIsLoading(false));
      } else {
        getMuestrasAspersorById(params.id)
          .then(({ data }) => {
            if (data) {
              setData(formatAddImage(data));
            }
          })
          .catch((e) =>
            Swal.fire({
              icon: "error",
              title: "error al obtener datos del id",
              text: e,
            })
          )
          .finally(() => setIsLoading(false));
      }
    }
  }, [params.id]);

  const formatAddImage = (data) => {
    return data.map((obj) => {
      obj["imagen"] = <img src={`${obj.imagen}`} width={"100%"} />;
      return obj;
    });
  };

  return { data, isLoading };
};
