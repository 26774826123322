export const reportsFiltersTable = [
  {
    name: "Codigo referente",
    selector: (row) => row.res1,
    sortable: true,
  },
  {
    name: "Codigo dispositivo",
    selector: (row) => row.ovitrampa_codigo,
    sortable: true,
  },
  {
    name: "Imagen",
    selector: (row) => row.imagen,
    sortable: true,
  },
  {
    name: "Estado",
    selector: (row) => row.estado,
  },
  {
    id:'date',
    name: "Fecha de carga",
    selector: (row) => row.fecha_carga,
    sortable: true,
  },
];
