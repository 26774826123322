import { getLocalidades } from "api/localidades.routes"
import { useMemo, useState } from "react"
import Swal from "sweetalert2"

export const useGetLocalidades = () => {
    const [localidades, setLocalidades] = useState()
    
    useMemo(() => {
        const data = getLocalidades()
        .then(({data}) => setLocalidades(data) )
        .catch((e) => Swal.fire({
            icon:'error',
            title:'Error al obtener localidades',
            text:e
        }))

        return data

    }, [''])

    return {
        localidades
    }
}