import formatResponse from "helpers/formatResponse";

export const reportsTable = [
    {
      name: "Pregunta 1",
      selector: (row) => formatResponse(row.res1.descr),
      sortable: true,
    },
    {
      name: "Pregunta 2",
      selector: (row) => formatResponse(row.res2.descr),
      sortable: true,
    },
    {
      name: "Pregunta 3",
      selector: (row) => formatResponse(row.res3.descr),
      sortable: true,
    },
    {
      name: "Pregunta 4",
      selector: (row) => formatResponse(row.res4.descr),
      sortable: true,
    },
    {
      name: "Imagen",
      selector: (row) => row.imagen,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.estado,
    },
    {
      name: "Resultado",
      selector: (row) => row.resultado,
      sortable: true,
    },
    {
      id:'date',
      name: "Fecha de carga",
      selector: (row) => row.fecha_carga,
      sortable: true,
    },
  ];