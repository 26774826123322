import { useMaterialUIController, setMiniSidenav } from "context";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import routes from "routes/routes";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import brandWhite from "assets/images/logo-ct.png";
import { CssBaseline } from "@mui/material";

function Layout() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <>
      <CssBaseline />
      {layout === "dashboard" && (
        <Sidenav
          color={sidenavColor}
          brand={brandWhite}
          brandName="Panel administración"
          routes={[...routes.private, ...routes.public]}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      <Outlet />
    </>
  );
}

export default Layout;
