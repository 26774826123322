import { Box } from '@mui/material'
import { postNuevoCodigoOvitrampa } from 'api/ovitrampa.routes'
import { FormButtons } from 'components/FormButtons'
import MDInput from 'components/MDInput'
import MDTypography from "components/MDTypography";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { useParams } from "react-router-dom"
import { useForm } from "react-hook-form"

export const ChangeOvitrampa = () => {
  
  const {
    register,
    handleSubmit,
    formState: { errors }
    } = useForm();
  
  const params = useParams();

  const [dni, setDni] = useState() 
  const [codigo_ovitrampa, setCodigoOvitrampa] = useState()

  useEffect(() => {
    setDni(params.dni)
    setCodigoOvitrampa(params.codigo_ovitrampa)
    
}, [])
 
  const changeOvitrampa = (data) => {
    postNuevoCodigoOvitrampa({
      dni: dni,
      codigo_viejo: codigo_ovitrampa,
      codigo_nuevo: data.codigoNuevo,
    })
      .then(() =>
        Swal.fire({
          icon: "success",
          text: "Datos actualizados correctamente",
          timer: 2000,
        })
      )
      .catch((error) =>
        Swal.fire({
          icon: "error",
          text: error,
          timer: 4000,
        })
      );
  };

  return (
    <DashboardLayout>
      <h1>Cambiar Ovitrampa</h1>
      <form onSubmit={handleSubmit(changeOvitrampa)}>
        <Box display={"flex"}>
          <Box mr={5} width={"40%"}>
            <MDInput
              disabled="true"
              label="DNI"
              type="text"
              value={dni}
            />
          </Box>
          <Box mr={5} width={"40%"}>
            <MDInput
              disabled="true"
              label="Código Actual"
              type="text"
              value={codigo_ovitrampa}
            />            
          </Box>
          <Box mr={5} width={"40%"}>
            <MDInput
              label="Código Nuevo"
              type="text"
              {...register("codigoNuevo", {
                required: true,
                maxLength: { value: 8, message: "Ingrese el formato de ovitrampa O-123456" },
                minLength: { value: 8, message: "Ingrese el formato de ovitrampa O-123456" },
                pattern: /^[O]?-[0-9]+$/i
              })}
            />
            {errors.codigoNuevo?.type === "required" && (
              <MDTypography color="error">Debe ingresar un código</MDTypography>
            )}
            {errors.codigoNuevo?.type === "pattern" && (
              <MDTypography color="error">Ingrese el formato de ovitrampa O-123456</MDTypography>
            )}
            <MDTypography color="error">{errors?.codigoNuevo?.message}</MDTypography>
          </Box>
        </Box>
        <FormButtons />
      </form>
    </DashboardLayout>
  )
}
