import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { FormEditUsers } from "components/Forms/FormEditUsers";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useFormEditUsers } from "hooks/useFormEditUsers";
import Error404 from "pages/404";
import Cargando from "pages/Cargando";
import { useNavigate, useParams } from "react-router-dom";

export const FormDataSupervisor = () => {
  const {
    localidades,
    localidad,
    register,
    handleSubmit,
    errors,
    handleLocalidad,
    onSubmit,
    isLoading,
    familyData,
  } = useFormEditUsers();
  const navigate = useNavigate()
  const params = useParams()

  if (isLoading) return <Cargando />;
  if (!familyData) return <Error404 />;
  return (
    <DashboardLayout>
      <h1>Datos de supervisor</h1>
      <FormEditUsers
        localidades={localidades}
        localidad={localidad}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        handleLocalidad={handleLocalidad}
        onSubmit={onSubmit}
        familyData={familyData}
      >
        <Box marginX={2}>
        <Box marginY={2}>
          <Grid item xs={12}>
            <p>Programa</p>
            <FormControlLabel
              control={
                <Checkbox {...register("ovitrampa")} defaultChecked={familyData?.ovitrampa} />
              }
              label="Ovitrampa"
            />
            <FormControlLabel
              control={<Checkbox {...register("kit")} defaultChecked={familyData?.kit} />}
              label="Aspersor"
            />
          </Grid>
        </Box>
        <Box marginY={2}>
            <Grid item xs={12}>
              <MDButton color="info" onClick={() => navigate(`/admins/changePassword/${params.id}`)}>
                Cambiar contraseña
              </MDButton>
            </Grid>
        </Box>
        </Box>
      </FormEditUsers>
    </DashboardLayout>
  );
};
