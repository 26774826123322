import { serviceApi } from "./api-config";
import qs from "qs";

export const getUsuariosActivos = async (localidadId) => {
  try {
    const { data } = await serviceApi.post(`api/procev/usuarios/activos`, localidadId);
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const getUsuariosInactivos = async (localidadId) => {
  try {
    const { data } = await serviceApi.post(`api/procev/usuarios/inactivos`, {
      id_localidad: localidadId,
    });
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const postCambiarEstadoUsuario = async (id, estado) => {
  try {
    const { data } = await serviceApi.post(`api/procev/usuarios/modificarEstado`, { id, estado });
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const postActualizarDatosDelUsuario = async (userData) => {
  try {
    const { data } = await serviceApi.post(
      `api/procev/usuarios/actualizarDatosUsuario`,
      qs.stringify(userData)
    );
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const postRegistrarUsuario = async (userData) => {
  try {
    if(userData.codigo.toUpperCase().includes("A-")){
      const { data } = await serviceApi.post(`api/procev/aspersor/register`, qs.stringify(userData));
      return data;
    }else{
      const { data } = await serviceApi.post(`api/procev/ovitrampa/register`, qs.stringify(userData));
      return data;
    }
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const postRegistraSupervisor = async (userData) => {
  try {
    const { data } = await serviceApi.post(
      `api/procev/ovitrampa/register_supervisor`,
      qs.stringify(userData)
    );
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const postRegistrarUsuarioPanel = async (userData) => {
  try {
    const { data } = await serviceApi.post(`api/procev/register_usuario`, qs.stringify(userData));
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const getFiltersReportsOvi = async (filters) => {
  try {
    const { data } = await serviceApi.post(
      `api/procev/ovitrampa/muestrasOvitrampasFiltros`,
      qs.stringify(filters)
    );
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const getFiltersReportsAsper = async (filters) => {
  try {
    const { data } = await serviceApi.post(
      `api/procev/aspersor/muestrasAspersorFiltros`,
      qs.stringify(filters)
    );
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const getActiveUsersPanel = async () => {
  try {
    const { data } = await serviceApi.post("api/procev/usuarios/activosP");
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const getInactiveUsersPanel = async () => {
  try {
    const { data } = await serviceApi.post("api/procev/usuarios/inactivosP");
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const postChangePassword = async (userData) => {
  try {
    const { data } = await serviceApi.post("api/procev/modificar_password", qs.stringify(userData));
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const postRegisterAgent = async (userData) => {
  try {
    const { data } = await serviceApi.post(`api/procev/casos/register_agente`, qs.stringify(userData));
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};