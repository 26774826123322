export const vectorTable = [
  {
    name: "Nombre",
    selector: (row) => row.nombre.toUpperCase(),
    sortable: true,
    width: '180px'
  },
  {
    name: "Apellido",
    selector: (row) => row.apellido.toUpperCase(),
    sortable: true,
    width: '180px'
  },
  {
    name: "Domicilio",
    selector: (row) => row.domicilio,
    sortable: true,
    width: '300px',
  },
  {
    name: "Localidad",
    selector: (row) => row.localidad,
    sortable: true,
    width: "auto",
    width:'180px'
  },
  {
    name: "DNI",
    selector: (row) => row.dni,
    width:'100px'
  },
  {
    name: "Código dispositivo",
    selector: (row) => (
      <div>
        <p>{row.codigo_ovitrampa} </p>
        <p>{row.codigo_aspersor}</p>
      </div>
    ),
  },
  {
    name: "Resultado",
    selector: (row) => row.resultado,
  },
  {
    name: "Fecha Resultado",
    selector: (row) => row.fecha_resultado,
    sortable: true,
    width:'200px'
  },
];
