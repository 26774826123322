import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip } from "@mui/material";
import { getSuspectByDni, getSuspects } from "api/suspect.routes";
import { FilterComponent } from "components/Filters/FilterComponent";
import { NoData } from "components/NoData/NoData";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import formatResponse from "helpers/formatResponse";
import Cargando from "pages/Cargando";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import MDButton from "components/MDButton";
import { downloadExcel } from "helpers/downloadExcel";
import { Box } from "@mui/system";
import { Export } from "components/Buttons/Export";

function Suspect() {
  const [suspect, setSuspects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getSuspects()
      .then((data) => {
        setSuspects(data.data);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Error al obtener los casos sospechosos",
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  function addQuestions(suspect) {
    const columns = [
      {
        name: "DNI",
        selector: (row) => row.persona_dni,
        sortable: true,
      },
      {
        name: "Resultado",
        selector: (row) => row.resultado,
        sortable: true,
      },
      {
        name: "Imagen",
        selector: (row) => (
          <Chip
            label={"Ver imagen"}
            variant="outlined"
            clickable={true}
            onClick={() => window.open(`${row.imagen}`, "_blank")}
          />
        ),
      },
    ];

    if (suspect.length > 0) {
      const questions = suspect[0];
      for (let i = 1; i < 4; i++) {
        columns.push({
          name: questions[`res${i}`].pregunta,
          selector: (row) => formatResponse(row[`res${i}`].descr),
        });
      }
      columns.push({
        name: "Fecha",
        selector: (row) => row.fecha_carga,
      });
    }

    return columns;
  }

  const filteredItems = suspect.filter((item) => {
    for (let key in item) {
      if (key !== "id") {
        if (
          item[key] &&
          item[key].toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1
        ) {
          return item;
        }
      }
    }
  });

  const conditionalRowStyles = [
    {
      when: (row) => row.resultado.includes("Positivo"),
      style: {
        backgroundColor: "#DF524C",
        color: "black",
      },
    },
  ];

  const formatForExcel = (array) => {
    return array.map((data) => {
      const line = {
        DNI: data.persona_dni,
        [`${data.res1.pregunta}`]: formatResponse(data.res1?.descr),
        [`${data.res2.pregunta}`]: formatResponse(data.res2?.descr),
        [`${data.res3.pregunta}`]: formatResponse(data.res3?.descr),
        Resultado: data.resultado,
        "Fecha de carga": data.fecha_carga,
        Imagen: data.imagen,
        Latitud: data.latitud,
        Longitud: data.longitud,
        Resultado: data.resultado,
        Comentario: data.comentario,
      };

      delete line.res1;
      delete line.res2;
      delete line.res3;
      delete line.res4;

      return line;
    });
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
      }
    };

    return (
      <>
        <Box mr={3}>
          <FilterComponent
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
          />
        </Box>
        <Export onExport={() => downloadExcel(formatForExcel(suspect), "Casos sospechosos")} />
      </>
    );
  }, [filterText]);

  if (isLoading) return <Cargando />;

  return (
    <DashboardLayout>
      <Box marginY={4} display={"flex"} width={"100%"} justifyContent={"space-between"}>
        <MDButton color="info" marginY={4} onClick={() => navigate("/suspect/map")}>
          Mapa
        </MDButton>
        <MDButton color="success" onClick={() => navigate("/suspect/register")}>
          + Agregar caso sospechosos
        </MDButton>
      </Box>
      {suspect.length > 0 ? (
        <DataTable
          columns={addQuestions(filteredItems)}
          data={filteredItems}
          conditionalRowStyles={conditionalRowStyles}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      ) : (
        <NoData />
      )}
    </DashboardLayout>
  );
}

const ExpandedComponent = ({ data }) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getSuspectByDni(data.persona_dni)
      .then((data) => {
        setUserData(data.data);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Error al obtener datos de la persona.",
        });
      })
      .finally(() => setIsLoading(false));
  }, [data]);

  if (isLoading || userData === null) return <p>Cargando...</p>;

  return (
    <div>
      <p>
        <b>Nombre:</b> {userData.nombre}
      </p>
      <p>
        <b>Apellido:</b> {userData.apellido}
      </p>
      <p>
        <b>DNI:</b> {userData.dni}
      </p>
      <p>
        <b>Telefono:</b> {userData.telefono}
      </p>
      <p>
        <b>Domicilio:</b> {userData.domicilio}
      </p>
      <p>
        <b>Localidad:</b> {userData.localidad}
      </p>
      <p>
        <b>Cantidad de integrantes grupo familiar:</b> {userData.cantidad}
      </p>
      <p>
        <b>Email:</b> {userData.email}
      </p>
    </div>
  );
};

export default Suspect;
