import { serviceApi } from "./api-config";
import qs from "qs";

export const getMuestrasById = async (id) => {
  try {
    const { data } = await serviceApi.get(`api/procev/ovitrampa/${id}/muestrasIdFamilias`);
    return data;
  } catch {
    throw new Error("Error al obtener las muestras del id ", id);
  }
};

export const getMuestrasAspersorById = async (id) => {
  try {
    const { data } = await serviceApi.get(`api/procev/aspersor/${id}/muestrasIdFamilias`);
    return data;
  } catch {
    throw new Error("Error al obtener las muestras del id ", id);
  }
};

export const getMuestrasByDni = async (dni) => {
  try {
    return await serviceApi.get(`api/procev/ovitrampa/${dni}/muestrasDniFamilias`);
  } catch {
    throw new Error("Error al obtener las muestras del dni ", dni);
  }
};

export const postEstadoMuestra = async (data) => {
  try {
    return await serviceApi.post(`api/procev/ovitrampa/estadoMuestra`, qs.stringify(data));
  } catch {
    throw new Error("Error al obtener las muestras del dni ", dni);
  }
};
