import { Box, Typography } from '@mui/material'
import { postPhoneNumber } from 'api/contact.routes'
import { getPhoneNumber } from 'api/contact.routes'
import { FormButtons } from 'components/FormButtons'
import MDInput from 'components/MDInput'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import React, { useState } from 'react'
import { useEffect } from 'react'
import Swal from 'sweetalert2'

export const Contacto = () => {

  const [phoneNumber, setPhoneNumber] = useState(null)

  useEffect(() => {
    getPhoneNumber()
    .then((data) => {
      setPhoneNumber(data.numero)
    })
    .catch((error) => {
      Swal.fire({
        icon:'error',
        title:error,
        text:"Intente nuevamente mas tarde"
      })
    })
  }, [])
 
  const handleSubmit = (e) => {
    e.preventDefault();
    postPhoneNumber(phoneNumber)
    .then((data) => {
      Swal.fire({
        icon:'success',
        title:data.mensaje,
      })
    })
    .catch((error) => {
      Swal.fire({
        icon:'error',
        title:error,
        text:"Intente nuevamente mas tarde"
      })
    })
  }

  return (
    <DashboardLayout>
      <Box>
        <Typography>
        Telefono de contacto
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box width={"50%"}>
          <MDInput value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
        </Box>
        <Box width={"50%"}>
        <FormButtons />
        </Box>
      </form>
    </DashboardLayout>
  )
}
