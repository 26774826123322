export const supervisorsTable = [
  {
    name: "Nombre",
    selector: (row) => row.nombre.toUpperCase(),
    sortable: true,
    width: '180px'
  },
  {
    name: "Apellido",
    selector: (row) => row.apellido.toUpperCase(),
    sortable: true,
    width: '180px'
  },
  {
    name: "Localidad",
    selector: (row) => row.localidad,
    sortable: true,
    width: '180px'
  },
  {
    name: "Domicilio",
    selector: (row) => row.domicilio,
    sortable: true,
    width: '300px'
  },
  {
    name: "DNI",
    selector: (row) => row.dni,
  },
  {
    name: "Reportes",
    selector: (row) => row.report,
    width:'180px'
  },
  {
    name: "Modificar",
    selector: (row) => row.acept,
    width:'160px'
  },
  {
    name: "Deshabilitar",
    selector: (row) => row.delete,
    width:'180px'
  },
];
