import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import styled from "styled-components";

function InfoLayout({children}) {
  return (
    <DashboardLayout>
      <InfoContainer>
        {children}
      </InfoContainer>
    </DashboardLayout>
  );
}

export default InfoLayout;

const InfoContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
