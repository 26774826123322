import { serviceApi } from "./api-config"

export const getCounters = async () => {
    try{
        const { data }  = await serviceApi.get(`api/procev/dashboard/getContadores`)
        return data
    }catch(err){
        console.log('error',err)
        throw new Error('Error al obtener contadores')
    }
}