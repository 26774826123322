import { Box } from "@mui/material";
import { postChangePassword } from "api/users.routes";
import { FormButtons } from "components/FormButtons";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

export const ChangePasswordView = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    watch,
  } = useForm();
  const params = useParams();
  const password = watch("password")
  const repassword = watch("repassword")

  const changePassword = (data) => {
    postChangePassword({
      id: params.id,
      password: data.password,
      repassword: data.repassword,
    })
      .then(() =>
        Swal.fire({
          icon: "success",
          text: "Datos actualizados correctamente",
          timer: 2000,
        })
      )
      .catch((error) =>
        Swal.fire({
          icon: "error",
          text: error,
          timer: 2000,
        })
      );
  };

  useEffect(() => {
    password !== repassword ? setError("notEq", { type: 'custom', message: 'Las claves no coinciden' }) : clearErrors("notEq")
  }, [password,repassword])
  
  return (
    <DashboardLayout>
      <h1>Cambiar contraseña de usuario</h1>
      <form onSubmit={handleSubmit(changePassword)}>
        <Box display={"flex"}>
          <Box mr={5} width={"40%"}>
            <MDInput
              label="Nueva contraseña"
              type="password"
              {...register("password", {
                required: true,
                maxLength: 20,
                minLength: { value: 6, message: "Minimo 6 caracteres" },
              })}
            />
            <MDTypography color="error">{errors?.password?.message}</MDTypography>
          </Box>
          <Box width={"40%"}>
            <MDInput
              label="Repetir nueva contraseña"
              type="password"
              {...register("repassword", {
                required: true,
                maxLength: 20,
                minLength: { value: 6, message: "Minimo 6 caracteres" },
              })}
            />
            <MDTypography color="error">{errors?.repassword?.message}</MDTypography>
            <MDTypography color="error">{errors?.notEq?.message}</MDTypography>
          </Box>
        </Box>
        <FormButtons />
      </form>
    </DashboardLayout>
  );
};
