import { useState, useEffect, useContext } from "react";
import AuthCotext from "context/AuthProvider";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "pages/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useLocation, useNavigate } from "react-router-dom";
import MDAlert from "components/MDAlert";
import { authLogin } from "api/auth.routes";

function Basic() {
  const { setAuth } = useContext(AuthCotext);

  const [isLoading, setIsLoading] = useState(false);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)
    authLogin(username, password)
    .then((resp) => {
      if(resp){
        if(resp.status === 200){
          localStorage.setItem("token", resp.data.access_token)
          localStorage.setItem("isAuth", true)
          localStorage.setItem("role", resp.data.role);
          setAuth({
            "isAuth": true
          })
          setUserName("");
          setPassword("");
          navigate(from, { replace: true });
        }
      }else{
        setAuth({
          "isAuth": false
        })
        setErrMsg("Usuario o contraseña incorrecto");
      }
    })
    .catch((err) => {
      setErrMsg("Usuario o contraseña incorrecto");
    })
    .finally(() => {
      setIsLoading(false)
    })
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Ingresar
          </MDTypography>
        </MDBox>
        {errMsg.length > 0 && (
          <MDBox pt={4} px={3}>
            <MDAlert color="error">{errMsg}</MDAlert>
          </MDBox>
        )}
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={(e) => handleSubmit(e)}>
            <MDBox mb={2}>
              <MDInput
                type="number"
                label="Usuario"
                fullWidth
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Contraseña"
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient"  color="info" disabled={isLoading} fullWidth type="submit">
                Ingresar
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
