import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

export const FormButtons = () => {
  const navigate = useNavigate()
  
  return (
    <Grid
      container
      xs={12}
      justifyContent={"center"}
      spacing={2}
      style={{
        marginTop: "10px",
      }}
    >
      <Grid item xs={12} md={3}>
        <MDButton variant="gradient" color="warning" fullWidth onClick={() => navigate(-1)} >
          Volver
        </MDButton>
      </Grid>
      <Grid item xs={12} md={3}>
        <MDButton variant="gradient" color="info" type={"submit"} fullWidth>
          Guardar cambios
        </MDButton>
      </Grid>
    </Grid>
  );
};
