import { positionsNames } from "./positionsNames/positionsNames";

export const rolNameFromNumber = ( roleNumber ) => {
    switch (roleNumber) {
        case 1:
            return positionsNames.SUPERADMIN;
        case 2:
            return positionsNames.ADMIN;
        case 3:
            return positionsNames.SUPERVISOR;
        case 4:
            return positionsNames.FAMILIA;
        case 5:
            return positionsNames.LABORATORIO;
        case 6:
            return positionsNames.USUARIO;
        case 7:
            return positionsNames.AGENTE
        default:
            console.log(`No existe rol con ese ID`);
    }
}