import { useEffect, useState } from 'react'

export const useFilterUsersByRole = (users, role) => {
    const [usersFilters, setUsersFilters] = useState([]);

    useEffect(() => {
        if(Array.isArray(users)){
            const usersArray = users.filter((user) => user.role === role)
            setUsersFilters(usersArray)
        }
    }, [users, role])
    
  return usersFilters
}
