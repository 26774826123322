import { Chip } from "@mui/material";
import { Box } from "@mui/system";
import { Return } from "components/Buttons/Return";
import MDButton from "components/MDButton";
import { NoData } from "components/NoData/NoData";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { downloadExcel } from "helpers/downloadExcel";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { reportsTable } from "./reportsTable";
import { useGetReports } from "./useGetReports";
import { getDatosFamiliesById } from "api/familias.routes";
import "../../styles/tableStyle.css";
import Cargando from "pages/Cargando";
import formatResponse from "helpers/formatResponse";
import { Export } from "components/Buttons/Export";

function Reports() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { data, isLoading } = useGetReports();
  const [userData, setUserData] = useState(null);

  const adapterExcel = (array) => {
    return array.map((data) => {
      const line = {
        id: data.id,
        [`${data.res1.pregunta}`]: formatResponse(data.res1?.descr),
        [`${data.res2.pregunta}`]: formatResponse(data.res2?.descr),
        [`${data.res3.pregunta}`]: formatResponse(data.res3?.descr),
        [`${data.res4.pregunta}`]: formatResponse(data.res4?.descr),
        Resultado: data.resultado,
        "Fecha de carga": data.fecha_carga,
        Imagen: data.imagen?.props.src,
      };

      delete line.res1;
      delete line.res2;
      delete line.res3;
      delete line.res4;

      return line;
    });
  };
  const actionsMemo = <Export onExport={() => downloadExcel(adapterExcel(data))} />;

  const addColumnTitles = (table, questions) => {
    const tableWithTitles = table.map((column, index) => {
      if (column.name === "Imagen") {
        return {
          ...column,
          selector: (row) => (
            <Chip
              label={"Ver imagen"}
              variant="outlined"
              clickable={true}
              onClick={() => window.open(`${row.imagen.props.src}`, "_blank")}
            />
          ),
        };
      }

      return {
        ...column,
        name: index <= 3 ? questions[`res${index + 1}`].pregunta : column.name,
      };
    });

    if (location.pathname.includes("supervisores")) {
      tableWithTitles.unshift({
        name: "Familia",
        selector: (row) => (
          <Chip
            label={row.dni_flia}
            variant="outlined"
            clickable={true}
            onClick={() =>
              navigate(
                `/familias/${row.ovitrampa_codigo ? "ovitrampa" : "aspersor"}/reportes/${
                  row.id_flia
                }`
              )
            }
          />
        ),
        sortable: true,
      });
    }

    return tableWithTitles;
  };

  useEffect(() => {
    getDatosFamiliesById(params.id)
      .then((data) => {
        setUserData(data);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Error al obtener datos del usuario",
          text: "Intente nuevamente mas tarde.",
        });
      });
  }, [location]);

  if (isLoading) return <Cargando />;
  return (
    <DashboardLayout>
      <Box mb={5}>
        <Return />
      </Box>
      {data.length > 0 ? (
        <DataTable
          title={`${userData?.data.dni} - ${userData?.data.nombre} ${userData?.data.apellido}`}
          columns={addColumnTitles(reportsTable, data[0])}
          data={data}
          pagination
          actions={actionsMemo}
          defaultSortFieldId={"date"}
          defaultSortAsc={false}
        />
      ) : (
        <NoData />
      )}
    </DashboardLayout>
  );
}
export default Reports;
