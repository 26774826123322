export const Suspect = (value) =>
  '<div id="content">' +
  '<div id="siteNotice">' +
  "</div>" +
  `<h1 id="firstHeading" class="firstHeading"> DNI: ${value.persona_dni}</h1>` +
  '<div id="bodyContent">' +
  "<ul>" +
  `<li>Resultado: ${value.resultado}</li>` +
  "</ul>" +
  "</div>" +
  "</div>";

export const Family = (value) =>
  '<div id="content">' +
  '<div id="siteNotice">' +
  "</div>" +
  `<h1 id="firstHeading" class="firstHeading"> <a href='#/familias/${
    value.codigo_ovitrampa ? "ovitrampa" : "aspersor"
  }/reportes/${value.id}' target='_blank'> ${
    value.codigo_ovitrampa || value.codigo_aspersor
  }</a></h1>` +
  '<div id="bodyContent">' +
  "<ul>" +
  `<li>Apellido: ${value.apellido}</li>` +
  `<li>Nombre: ${value.nombre}</li>` +
  `<li>DNI: ${value.dni}</li>` +
  `<li>Localidad: ${value.localidad}</li>` +
  `<li>Domicilio: ${value.domicilio}</li>` +
  `<li>Cantidad de integrantes: ${value.cantidad}</li>` +
  `<li>Telefono: ${value.telefono}</li>` +
  `<li>Email: ${value.email}</li>` +
  "</ul>" +
  "</div>" +
  "</div>";

export const Vector = (value) =>
  '<div id="content">' +
  '<div id="siteNotice">' +
  "</div>" +
  `<h1 id="firstHeading" class="firstHeading"> <a href='#/familias/${
    value.codigo_ovitrampa ? "ovitrampa" : "aspersor"
  }/reportes/${value.id}' target='_blank'> ${
    value.codigo_ovitrampa || value.codigo_aspersor
  }</a></h1>` +
  '<div id="bodyContent">' +
  "<ul>" +
  `<li>Apellido: ${value.apellido}</li>` +
  `<li>Nombre: ${value.nombre}</li>` +
  `<li>DNI: ${value.dni}</li>` +
  `<li>Localidad: ${value.localidad}</li>` +
  `<li>Domicilio: ${value.domicilio}</li>` +
  `<li>Cantidad de integrantes: ${value.cantidad}</li>` +
  `<li>Telefono: ${value.telefono}</li>` +
  `<li>Email: ${value.email}</li>` +
  `<li>Resultado: ${value.resultado}</li>` +
  `<li>Fecha de resultado: ${value.fecha_resultado}</li>` +
  "</ul>" +
  "</div>" +
  "</div>";
