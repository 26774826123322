export const familiesTable = [
  {
    name: "Nombre",
    selector: (row) => row.nombre.toUpperCase(),
    sortable: true,
    width: '180px'
  },
  {
    name: "Apellido",
    selector: (row) => row.apellido.toUpperCase(),
    sortable: true,
    width: '180px'
  },
  {
    name: "Domicilio",
    selector: (row) => row.domicilio,
    sortable: true,
    width: '300px',
  },
  {
    name: "Localidad",
    selector: (row) => row.localidad,
    sortable: true,
    width: "auto",
  },
  {
    name: "DNI",
    selector: (row) => row.dni,
  },
  {
    name: "Código dispositivo",
    selector: (row) => (
      <div>
        <p>{row.codigo_ovitrampa} </p>
        <p>{row.codigo_aspersor}</p>
      </div>
    ),
  },
  {
    name: "Programa",
    selector: (row) => row.program,
    sortable: true,
    width:'auto'
  },
  {
    name: "Reportes",
    selector: (row) => row.report,
    width:'160px'
  },
  {
    name: "Modificar",
    selector: (row) => row.acept,
    width:'160px'
  },
  {
    name: "Deshabilitar",
    selector: (row) => row.delete,
    width:'180px'
  },
];
