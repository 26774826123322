export const familiesReportTable = [
  {
    name: "Codigo dispositivo",
    selector: (row) => row.ovitrampa_codigo,
    sortable: true,
  },
  {
    name: "DNI",
    selector: (row) => row.familia_dni,
    sortable: true,
  },
  {
    name: "Imagen",
    selector: (row) => row.imagen,
    sortable: true,
  },
  {
    name: "Estado",
    selector: (row) => row.estado,
  },
  {
    name: "Resultado",
    selector: (row) => row.resultado,
    sortable: true,
  },
  {
    id:'date',
    name: "Fecha de carga",
    selector: (row) => row.fecha_carga,
    sortable: true,
  },
];
