import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export const NoData = () => {
  return (
    <Box display={"flex"} alignItems={"center"} width={"100%"} justifyContent={"center"} height={"100px"} style={{backgroundColor:"d7d7d7"}}>
        <Typography>
            No hay datos disponibles.
        </Typography>
    </Box>
  )
}
