import React, { useEffect, useState, useContext } from "react";
import { rolNameFromNumber } from "helpers/rolNameFromNumber";
import { LocalidadesContext } from "context/LocalidadesProvider";
import { Link } from "react-router-dom";
import { getActiveUsersPanel, getInactiveUsersPanel, postCambiarEstadoUsuario } from "api/users.routes";
import MDButton from "components/MDButton";
import Swal from "sweetalert2";

export const cambiarEstado = (id, estado, setUsers) => {
    postCambiarEstadoUsuario(id, estado)
    .then(() => {
        setUsers((prevState) => prevState.map((user) => user.id === id ? {...user, "active": !user.active} : user))
        Swal.fire({
            icon:'success',
            title:`Usuario correctamente ${estado === 'S' ? "habilitado" : "deshabilitado"}`,
            timer:2000
        })
    })
    .catch(() => {
        Swal.fire({
            icon:'error',
            title:`Error al intentar ${estado === 'S' ? "habilitar" : "deshabilitar"} al usuario`,
            text:"Intente nuevamente mas tarde"
        })
    })
}

export const useGetNewUsers = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { localidad } = useContext(LocalidadesContext);

  useEffect(() => {
    setIsLoading(true);
    getActiveUsersPanel()
      .then(({ data }) => {
        setUsers((prev) => [...prev, ...(data.map(el => ({...el, "active": true})) || []) ]);
      })
      .catch((e) => {
        if (err.response.status !== 404) {
          Swal.fire({
            icon: "error",
            title: "Error al obtener los usuarios activos",
          });
        }
      })
      .finally(() => setIsLoading(false));

    getInactiveUsersPanel()
    .then(({ data }) => {
      setUsers((prev) => [...prev, ...(data.map(el => ({...el, "active": false})) || [])]);
    })
    .catch((e) => {
      if (err.response.status !== 404) {
        Swal.fire({
          icon: "error",
          title: "Error al obtener los usuarios inactivos",
        });
      }
    })
    .finally(() => setIsLoading(false));
  }, [localidad]);

  const addActionsButtons = (data) => {
    return data?.map((obj) => {
      obj["rol"] = rolNameFromNumber(obj.role);

      obj["acept"] = (
        <MDButton
          type="button"
          color={obj.active ? "error" : "info"}
          onClick={() => cambiarEstado(obj.id, obj.active ? "N" : "S", setUsers)}
          style={{ with: "100%" }}
        >
          {obj.active ? "Deshabilitar" : "Habilitar"}
        </MDButton>
      );

      obj["modif"] = (
        <Link to={ `/admins/edit/${obj.id}`}>
          <MDButton
            type="button"
            color="warning"
            style={{
              width: "100%",
            }}
          >
            Modificar
          </MDButton>
        </Link>
      );

      return obj;
    });
  };

  return {
    users,
    isLoading,
    addActionsButtons,
  };
};
