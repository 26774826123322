import { useGetLocalidades } from "hooks/useGetLocalidades"
import { useState } from "react"
import { createContext } from "react"

export const LocalidadesContext = createContext(null)

export const LocalidadesProvider = ({children}) => {
    const { localidades } = useGetLocalidades()
    const [localidad, setLocalidad] = useState({
        id:"",
        name:""
    })

    const handleLocalidadSelection = (localidadSelected) => {
        setLocalidad(localidadSelected)
    }

    return <LocalidadesContext.Provider value={{localidad, localidades, handleLocalidadSelection}}>{children}</LocalidadesContext.Provider>
}
