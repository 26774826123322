import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();
        navigate("/authentication/sign-in");
    }, [])

    return <></>
    
}

export default Logout;