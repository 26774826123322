import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import { LocalidadesContext } from 'context/LocalidadesProvider'
import React, { useContext } from 'react'

export const FilterLocalidades = ({children}) => {
    const {localidad, localidades, handleLocalidadSelection} = useContext(LocalidadesContext);

    const handleChange = (e, a) => {
        handleLocalidadSelection({id: a.props.value, name: a.props.children})
    }

  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'} width={500}  justifyContent={'space-between'}>
            <p>Filtrar por localidad</p>
            <Box width={250}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Localidades</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={localidad.id}
                        label={localidad.name}
                        onChange={handleChange}
                        style={{
                            height:'2rem'
                        }}
                        IconComponent={() => <ClearIcon onClick={() => handleLocalidadSelection({id:'', value:''})} style={{cursor: 'pointer', marginRight:'5px'}} />}
                    >
                    {
                        localidades?.map((localidad) => {
                            return <MenuItem key={localidad.id} value={localidad.id}>{localidad.descripcion}</MenuItem>
                        })
                    }
                    </Select>
                </FormControl>
            </Box>
            
        </Box>
        {children}
    </Box>
  )
}
