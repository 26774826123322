import React from "react";
import { Chip } from "@mui/material";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { getUsuariosInactivos } from "api/users.routes";
import { rolNameFromNumber } from "helpers/rolNameFromNumber";
import { cambiarEstado } from "helpers/cambiarEstadoDeUsuario";
import Swal from "sweetalert2";
import { LocalidadesContext } from "context/LocalidadesProvider";
import { useContext } from "react";

export const useGetNewUsers = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { localidad } = useContext(LocalidadesContext);

  useEffect(() => {
    setIsLoading(true);
    getUsuariosInactivos(localidad.id)
      .then(({ data }) => {
        setUsers(data || []);
      })
      .catch((e) => {
        if (err.response.status !== 404) {
          Swal.fire({
            icon: "error",
            title: "Error al obtener los usuarios inactivos",
          });
        }
      })
      .finally(() => setIsLoading(false));
  }, [localidad]);

  const addActionsButtons = (data) => {
    return data?.map((obj) => {
      obj["program"] = (
        <div>
          {obj.ovitrampa && <Chip label="Ovitrampa" variant="outlined" />}
          {obj.kit && <Chip label="Aspersor" variant="outlined" />}
        </div>
      );

      obj["rol"] = rolNameFromNumber(obj.role);

      obj["acept"] = (
        <MDButton
          type="button"
          color="info"
          onClick={() => cambiarEstado(obj.id, "S", setUsers)}
          style={{ with: "100%" }}
        >
          Habilitar
        </MDButton>
      );

      return obj;
    });
  };

  return {
    users,
    isLoading,
    addActionsButtons,
  };
};
