/* eslint-disable */
import { Box } from "@mui/system";
import { FilterLocalidades } from "components/Filters/FilterLocalidades";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import * as React from "react";
import { useGetMapData } from "./hooks/useGetMapData";
import { useIsSuspect } from "hooks/useIsSuspect";
import Map from "components/Map/Map";

export default function Maps() {
  const {data, isLoading} = useGetMapData()
  const isSuspect = useIsSuspect();


  return (
    <DashboardLayout>
      <p>Mapa</p>
      <Box marginY={5}>
        <FilterLocalidades/>
      </Box>
      {isLoading ?
        <h2>Cargando...</h2> :
        <Map data={data} pinType={isSuspect ? "suspect" : "family"} isLoading={isLoading}/>
       }
    </DashboardLayout>
  );
}