import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { FormEditUsers } from "components/Forms/FormEditUsers";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useFormEditUsers } from "hooks/useFormEditUsers";
import Error404 from "pages/404";
import Cargando from "pages/Cargando";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const FormDataAdmins = () => {
  const {
    localidades,
    localidad,
    register,
    handleSubmit,
    errors,
    handleLocalidad,
    onSubmit,
    isLoading,
    familyData,
    handleRole
  } = useFormEditUsers();
  const [adminRole, setAdminRole] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const handleChangeRole = (e) => {
    handleRole(e.target.value)
    setAdminRole(e.target.value)
  }

  useEffect(() => {
    if(familyData){
      setAdminRole(familyData.role)
    }
  }, [familyData])

  if (isLoading) return <Cargando />;
  if (!familyData) return <Error404 />;
  return (
    <DashboardLayout>
      <h1>Datos de usuarios administradores</h1>
      <FormEditUsers
        localidades={localidades}
        localidad={localidad}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        handleLocalidad={handleLocalidad}
        onSubmit={onSubmit}
        familyData={familyData}
      >
        <Box marginX={2}>
          <Box marginY={2}>
            <Grid item xs={6}>
              <FormControl sx={{minWidth: 120, height:45 }}>
                <InputLabel id="demo-select-small">Rol</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={adminRole}
                  label="Rol"
                  onChange={handleChangeRole}
                  style={{ height: '100%' }}
                >
                  {
                    !location.pathname.includes('edit') &&
                    <MenuItem value={2}>Administrador</MenuItem>
                  }
                  <MenuItem value={6}>Usuario</MenuItem>
                  <MenuItem value={5}>Laboratorista</MenuItem>
                  <MenuItem value={7}>Agente sanitario</MenuItem>
                </Select>
              </FormControl>
              {errors.password?.type === "required" && (
                <MDTypography color="error">Rol es obligatorio</MDTypography>
              )}
            </Grid>
          </Box>
          <Box marginY={2}>
            <Grid item xs={12}>
              <MDButton color="info" onClick={() => navigate(`/admins/changePassword/${params.id}`)}>
                Cambiar contraseña
              </MDButton>
            </Grid>
          </Box>
          <Box display={'none'}>
              <MDInput {...register("cantidad")}  value={"1"} />
          </Box>
        </Box>
      </FormEditUsers>
    </DashboardLayout>
  );
};
