import { Box } from "@mui/system";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React from "react";

export const FilterComponent = ({ onFilter, onClear, filterText }) => {
  return (
    <Box display={"flex"}>
      <MDInput
        type="text"
        label="Filtrar"
        fullWidth
        onChange={onFilter}
        value={filterText}
      />
      <MDButton onClick={() => onClear()} color="info">
        Limpiar
      </MDButton>
    </Box>
  );
};
