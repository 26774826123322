import { serviceApi } from "./api-config"
import qs from "qs";

export const postNuevoCodigoOvitrampa = async (userData) => {
    try{
        const { data } = await serviceApi.post(`/api/procev/ovitrampa/changeOvitrampa`, qs.stringify(userData))
        return data
    }catch (e){
        throw new Error('Error al intentar modificar la Ovitrampa')
    }
}