import { getDatosFamiliesById } from "api/familias.routes"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

export const useGetFamilyData = () => {
    const params = useParams()
    const [familyData, setFamilyData] = useState(null) 
    const [isLoading, setIsLoading] = useState(false) 

    useEffect(() => {
        setIsLoading(true)
        getDatosFamiliesById(params.id)
        .then((data) => {
            if(data.codigo === '00000'){
                setFamilyData(data.data)
            }
        })
        .catch((e) => console.log('Error.. ',e))
        .finally(() => setIsLoading(false))
    }, [params.id])

    return {
        familyData,
        isLoading
    }

}