export const newUserTable = [
    {
      name: "Nombre",
      selector: (row) => row.nombre.toUpperCase(),
      width: '180px'
    },
    {
      name: "Apellido",
      selector: (row) => row.apellido.toUpperCase(),
      width: '180px'
    },
    {
      name: "Domicilio",
      selector: (row) => row.domicilio,
      sortable: true,
      width: '300px'
    },
    {
      name: "DNI",
      selector: (row) => row.dni,
    },
    {
      name: "Código de dispositivo",
      selector: (row) => row.codigo_ovitrampa,
    },
    {
      name: "Programa",
      selector: (row) => row.program,
      sortable: true,
    },
    {
      name: "Rol",
      selector: (row) => row.rol,
      sortable: true,
    },
    {
      name: "Habilitar",
      selector: (row) => row.acept,
      width: '180px'
    }
  ];