// Material Dashboard 2 React layouts
import SignIn from "pages/authentication/sign-in";
import Dashboard from "pages/dashboard";

// @mui icons
import Icon from "@mui/material/Icon";
import NewUsers from "pages/newUsers";
import Maps from "pages/maps";
import Families from "pages/families";
import Supervisors from "pages/supervisors";
import Suspect from "pages/suspect";
import Logout from "pages/authentication/logout";
import Reports from "pages/reports";
import { FormDataFamily } from "pages/FormDataFamily";
import { Register } from "pages/register";
import { Contacto } from "pages/contacto";
import { Reportes } from "pages/reportes";
import { Lab } from "pages/lab";
import NewUsersPanel from "pages/newUserPanel";
import { FormDataSupervisor } from "pages/FormDataSupervisor";
import { FormDataAdmins } from "pages/FormDataAdmins";
import { ChangePasswordView } from "pages/changePassword";
import { ChangeOvitrampa } from "pages/changeOvitrampa";

export const ROLES = {
  SUPERADMIN: "1",
  ADMIN: "2",
  SUPERVISOR: "3",
  FAMILIAS: "4",
  LABORATORIO: "5",
  USUARIO: "6",
  AGENTE: "7",
  SALUD: "8",
};

const routes = {
  private: [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/dashboard",
      component: Dashboard,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO],
    },
    {
      type: "collapse",
      name: "Reportes",
      key: "reportes",
      icon: <Icon fontSize="small">article</Icon>,
      route: "/reportes",
      component: Reportes,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO],
    },
    {
      type: "collapse",
      name: "Mapa",
      key: "mapa",
      icon: <Icon fontSize="small">map</Icon>,
      route: "/mapa",
      component: Maps,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO],
    },
    {
      type: "collapse",
      name: "Alta de usuarios",
      key: "alta-usuarios",
      icon: <Icon fontSize="small">verifiedUser</Icon>,
      route: "/alta-usuarios",
      component: NewUsers,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO],
    },
    {
      type: "collapse",
      name: "Familias",
      key: "familias",
      icon: <Icon fontSize="small">people</Icon>,
      route: "/familias",
      component: Families,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO],
    },
    {
      type: "collapse",
      name: "Referentes",
      key: "supervisores",
      icon: <Icon fontSize="small">badge</Icon>,
      route: "/supervisores",
      component: Supervisors,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO],
    },
    {
      type: "collapse",
      name: "Caso sospechoso",
      key: "suspect",
      icon: <Icon fontSize="small">medicationIcon</Icon>,
      route: "/suspect",
      component: Suspect,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO, ROLES.SALUD],
    },
    {
      type: "collapse",
      name: "Contacto",
      key: "contacto",
      icon: <Icon fontSize="small">phone</Icon>,
      route: "/contacto",
      component: Contacto,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN],
    },
    {
      type: "collapse",
      name: "Usuarios",
      key: "admins",
      icon: <Icon fontSize="small">personAdd</Icon>,
      route: "/admins",
      component: NewUsersPanel,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.SALUD],
    },
    {
      route: "/admins/register",
      component: Register,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN],
    },
    {
      type: "collapse",
      name: "Laboratorio",
      key: "lab",
      icon: <Icon fontSize="small">scienceIcon</Icon>,
      route: "/lab",
      component: Lab,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.LABORATORIO],
    },
    {
      type: "collapse",
      name: "Salir",
      key: "sign-in",
      icon: <Icon fontSize="small">login</Icon>,
      route: "/authentication/users",
      component: Logout,
      roles: [
        ROLES.SUPERADMIN,
        ROLES.ADMIN,
        ROLES.USUARIO,
        ROLES.LABORATORIO,
        ROLES.FAMILIAS,
        ROLES.SUPERVISOR,
        ROLES.AGENTE,
        ROLES.SALUD
      ],
    },
    {
      route: "/supervisores/reportes/:id",
      component: Reports,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO],
    },
    {
      route: "/familias/ovitrampa/reportes/:id",
      component: Reports,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO, ROLES.LABORATORIO],
    },
    {
      route: "/familias/aspersor/reportes/:id",
      component: Reports,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO],
    },
    {
      route: "/familias/edit/:id",
      component: FormDataFamily,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO],
    },
    {
      route: "/supervisores/edit/:id",
      component: FormDataSupervisor,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO],
    },
    {
      route: "/admins/edit/:id",
      component: FormDataAdmins,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO],
    },
    {
      route: "/register/familia",
      component: Register,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO],
    },
    {
      route: "/register/supervisor",
      component: Register,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.USUARIO],
    },
    {
      route: "admins/changePassword/:id",
      component: ChangePasswordView,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN],
    },
    {
      route: "changeOvitrampa/:dni/:codigo_ovitrampa",
      component: ChangeOvitrampa,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN],
    },
    {
      route: "/suspect/map",
      component: Maps,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.SALUD],
    },
    {
      route: "/suspect/register",
      component: Register,
      roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.SALUD],
    },
  ],

  public: [
    {
      route: "/authentication/sign-in",
      component: SignIn,
    },
  ],
};

export default routes;
