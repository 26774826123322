import { Box } from "@mui/material";
import { FilterComponent } from "components/Filters/FilterComponent";
import { FilterLocalidades } from "components/Filters/FilterLocalidades";
import MDButton from "components/MDButton";
import { NoData } from "components/NoData/NoData";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useGetDataTable } from "hooks/useGetDataTable";
import Cargando from "pages/Cargando";
import { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { supervisorsTable } from "./supervisorsTable";
import { Export } from "components/Buttons/Export";
import '../../styles/tableStyle.css'
import { downloadExcel } from "helpers/downloadExcel";

const Supervisors = () => {
  const SUPERVISORS_ROL_ID = 3;
  const navigate = useNavigate();
  const { filtered, isLoading, addActionsButtons } = useGetDataTable(SUPERVISORS_ROL_ID, true);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = filtered.filter(
    item => {
      for (let key in item) {
        if(key !== 'id'){
          if (item[key] && (item[key].toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1)) {
            return item;
          }
        }
      }
    }

  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const adapterExcel = (array) => {
    return array.map((data) => {
      const line = {
        id: data.id,
        Nombre: data.nombre,
        Apellido: data.apellido,
        DNI: data.dni,
        Telefono: data.telefono,
        Domicilio: data.domicilio,
        Barrio: data.barrio,
        Email: data.email,
        Localidad: data.localidad,
      };

      return line;
    });
  };

  const actionsMemo = <Export onExport={() => downloadExcel(adapterExcel(filteredItems), "Reportes-supervisores")} />;

  if (isLoading) return <Cargando />;
  return (
    <DashboardLayout>
      <Box marginY={4}>
        <FilterLocalidades>
          <MDButton color="success" onClick={() => navigate("/register/supervisor")}>
            + Agregar supervisor
          </MDButton>
        </FilterLocalidades>
      </Box>
      {filtered.length > 0 ? (
        <DataTable
          columns={supervisorsTable}
          data={addActionsButtons(filteredItems)}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          actions={actionsMemo}
        />
      ) : (
        <NoData />
      )}
    </DashboardLayout>
  );
};

export default Supervisors;
