import { responseErrorHandler } from 'helpers/services/apiErorr';
import { serviceApi } from './api-config'

// --- Handlers ---
const requestHandler = (request) => {
    // Token will be dynamic so we can use any app-specific way to always
    // fetch the new token before making the call
    const token = localStorage.getItem("token");
    request.headers.common['Authorization'] =  "Bearer " + token ;
    //TODO: Add loading element to DOM
    return request;
};
const errorHandler = (error) => {    
    return error;
};

const responseHandler = (response) => {
    //TODO: Remove Loading element from DOM
    return response;
};

function addInterceptors(instance) {
    instance.interceptors.request.use(
        (request) => requestHandler(request),
        (error) => errorHandler(error)
    );
    instance.interceptors.response.use(
        (request) => responseHandler(request),
        (error) => responseErrorHandler(error)
    );
}

function setUpInterceptor () {
    addInterceptors(serviceApi)
}

export default setUpInterceptor