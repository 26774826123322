
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Footer from "examples/Footer";
import { useEffect, useState } from "react";
import { getCounters } from "api/dashboard.routes";
import MDAlert from "components/MDAlert";
import Cargando from "pages/Cargando";

function Dashboard() {
  const [counters, setCounters] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    getCounters().then((data) => setCounters(data))
    .catch((err) => alert(err) )
    .finally(() => setLoading(false))

  }, [])

  if(loading || counters === null){
    return <Cargando/>
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>

            {
              Object.keys(counters.data).map((key) => {
                return<Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title={key.split("_").join(" ").toUpperCase()}
                    count={counters.data[key]}
                  />
                </MDBox>
              </Grid>
                
              })
            }
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
