import { serviceApi } from "./api-config";
import qs from "qs";

export const getSuspects = async () => {
  try {
    const { data } = await serviceApi.get("api/procev/casos/getCasosSospechosos");
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const getSuspectsFiltered = async (filters) => {
  try {
    const { data } = await serviceApi.post(
      "api/procev/casos/getCasosSospechososFiltros",
      qs.stringify(filters)
    );
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const getSuspectByDni = async (dni) => {
  try {
    const { data } = await serviceApi.get(`api/procev/casos/${dni}/getPersonaDni`);
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const getPreguntas = async () => {
  try {
    const { data } = await serviceApi.get(`api/procev/casos/5/preguntas`);
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};

export const postRegisterCase = async (suspectCase) => {
  try {
    const { data } = await serviceApi.post(`api/procev/casos/registrar_caso_panel`, qs.stringify(suspectCase));
    return data;
  } catch (error) {
    throw new Error(error.response.data.mensaje);
  }
};
