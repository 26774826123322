import React, { useState } from "react";
import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getDatosFamiliesByCode } from "api/familias.routes";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Swal from "sweetalert2";
import { postEstadoMuestra } from "api/muestras.routes";
import { useNavigate } from "react-router-dom";

export const Lab = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [code, setCode] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();

  const searchUser = (e, code) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    getDatosFamiliesByCode(code)
      .then((data) => {
        if (data.data.length === 0) {
          setError(data.mensaje);
          setUserData(null);
        } else {
          setUserData(data.data);
        }
      })
      .catch((error) => {
        setUserData(null);
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
        });
      })
      .finally(() => setIsLoading(false));
  };

  const setResult = (result) => {
    setIsButtonDisabled(true);
    postEstadoMuestra({
      codigo: code,
      resultado: result,
    })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: `Resultado cargado correctamente`,
          timer: 2000,
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: `Error al intentar cargar resultado`,
          text: "Intente nuevamente mas tarde",
        });
      })
      .finally(() => setIsButtonDisabled(false));
  };

  return (
    <DashboardLayout>
      <h1>Registrar resultado de muestra</h1>
      <form onSubmit={(e) => searchUser(e, code)}>
        <label>Buscar por codigo de Ovitrampa</label>
        <MDInput onChange={(e) => setCode(e.target.value)} />
        <Box mt={3}>
          <MDButton color="info" type="submit">
            Buscar
          </MDButton>
        </Box>
      </form>

      {isLoading && <h1>Cargando...</h1>}
      {error && (
        <Box mt={5}>
          <p>
            <b>{error}</b>
          </p>
        </Box>
      )}
      {userData && (
        <Box mt={5}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ display: "table-header-group" }}>
                <TableRow>
                  <TableCell align="left">Apellido</TableCell>
                  <TableCell align="left">Nombre</TableCell>
                  <TableCell align="left">Codigo ovitrampa</TableCell>
                  <TableCell align="left">Telefono</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Resultado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={userData.apellido}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {userData.apellido}
                  </TableCell>
                  <TableCell align="left">{userData.nombre}</TableCell>
                  <TableCell align="left">
                    <Chip
                      label={userData.codigo_ovitrampa}
                      variant="outlined"
                      clickable={true}
                      onClick={() => navigate(`/familias/ovitrampa/reportes/${userData.id}`)}
                    />
                  </TableCell>
                  <TableCell align="left">{userData.telefono}</TableCell>
                  <TableCell align="left">{userData.email}</TableCell>
                  <TableCell align="left">
                    <MDButton
                      color="success"
                      onClick={() => setResult("Positivo")}
                      disabled={isButtonDisabled}
                    >
                      Positivo
                    </MDButton>
                    <MDButton
                      color="warning"
                      onClick={() => setResult("Negativo")}
                      disabled={isButtonDisabled}
                    >
                      Negativo
                    </MDButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </DashboardLayout>
  );
};
