import React, { useEffect, useState } from "react";
import { getSuspectsFiltered } from "api/suspect.routes";
import { getUsuariosActivos } from "api/users.routes";
import { LocalidadesContext } from "context/LocalidadesProvider";
import { useIsSuspect } from "hooks/useIsSuspect";
import Swal from "sweetalert2";

export const useGetMapData = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const { localidad } = React.useContext(LocalidadesContext);
  const isSuspectsMap = useIsSuspect();
  const filters = {
    id_localidad: localidad.id,
  };

  useEffect(() => {
    setData([])
    setIsLoading(true)
    if (isSuspectsMap) {
      getSuspectsFiltered(filters)
        .then(({ data }) => {
          setData(data.filter((data) => data.resultado === "Positivo"));
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Error al obtener los registros",
            text: "Intente nuevamente mas tarde.",
          });
        })
        .finally(() => setIsLoading(false) );
    } else {
      getUsuariosActivos(filters)
        .then(({ data }) => {
          setData(data);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Error al obtener los registros",
            text: "Intente nuevamente mas tarde.",
          });
        })
        .finally(() => setIsLoading(false) )
    }
  }, [localidad.id, location]);

  return { data, isLoading };
};
