import InfoLayout from "layouts/DisplayInfo";

function Error404({errorMessage="Error 404"}) {
  return (
    <InfoLayout>
        <h1>{errorMessage}</h1>
    </InfoLayout>
  );
}

export default Error404;
