import { serviceApi } from "./api-config"

export const getPhoneNumber = async () => {
    try{
        const { data } = await serviceApi.get(`/api/procev/ovitrampa/numero_wpp`)
        return data
    }catch{
        throw new Error('Error al obtener el numero de telefono')
    }
}

export const postPhoneNumber = async (numero) => {
    try{
        const { data } = await serviceApi.post(`/api/procev/ovitrampa/numero_wpp`, {numero})
        return data
    }catch{
        throw new Error('Error al intentar modificar el numero de telefono')
    }
}