import { serviceApi } from "./api-config"

export const authLogin = async (user, password) => {
    const data = {
        'dni': user,
        'password': password
    }
    try{
        return await serviceApi.post('api/procev/login_panel', data)
    }catch{
        throw new Error('Error al loguearse')
    }
}