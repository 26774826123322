import { serviceApi } from "./api-config";
import qs from "qs";

export const getSupervisorsReports = async (id) => {
  try {
    const { data } = await serviceApi.get(`api/procev/ovitrampa/${id}/visitasOvitrampasSupervisor`);
    return data;
  } catch {
    throw new Error("Error al obtener los reportes del supervisor ", id);
  }
};

export const postSupervisorsReportsFiltered = async (filters) => {
  try {
    const { data } = await serviceApi.post(
      `api/procev/ovitrampa/visitasOvitrampasFiltros`,
      qs.stringify(filters)
    );
    return data;
  } catch {
    throw new Error("Error al obtener los reportes del supervisor ", id);
  }
};
