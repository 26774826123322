import React, { useState, useEffect } from "react";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useFormRegisterUsers } from "hooks/useFormRegisterUser";
import { useLocation } from "react-router-dom";
import { FormEditUsers } from "../../components/Forms/FormEditUsers";
import { useIsSuspect } from "hooks/useIsSuspect";
import { getPreguntas } from "api/suspect.routes";
import Swal from "sweetalert2";

export const Register = () => {
  const location = useLocation();
  const [isViewFamilia] = useState(location.pathname.includes("familia"));
  const [isViewAdmin] = useState(location.pathname.includes("admins"));
  const [isViewSupervisor] = useState(location.pathname.includes("supervisor"));
  const [isViewSuspect] = useState(location.pathname.includes("suspect"));
  const isSuspect = useIsSuspect();

  const {
    localidades,
    localidad,
    register,
    handleSubmit,
    errors,
    handleLocalidad,
    onSubmit,
    familyData,
    handleRole,
  } = useFormRegisterUsers();

  useEffect(() => {
    if (isViewFamilia) {
      handleRole(4);
    } else if (isViewAdmin) {
      // handleRole(adminRole);
    } else {
      //Se crea un supervisor
      handleRole(3);
    }
  }, []);

  return (
    <DashboardLayout>
      <h1>Registrar {isSuspect ? "caso sospechoso" : "usuario"}</h1>
      <FormEditUsers
        localidades={localidades}
        localidad={localidad}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        handleLocalidad={handleLocalidad}
        onSubmit={onSubmit}
        familyData={familyData}
      >
        {isViewFamilia && (
          <FamilyInputs errors={errors} register={register} familyData={familyData} />
        )}
        {isViewSupervisor && (
          <UserPanelInputs errors={errors} register={register} familyData={familyData} />
        )}
        {isViewAdmin && (
          <AdminInputs
            handleRole={handleRole}
            errors={errors}
            familyData={familyData}
            register={register}
          />
        )}
        {isViewSuspect && <SuspectInput errors={errors} register={register} />}
      </FormEditUsers>
    </DashboardLayout>
  );
};

const SuspectInput = ({ errors, register }) => {
  const [preguntas, setPreguntas] = useState([]);
  const [respuestas, setRespuestas] = useState(null);
  const now = new Date();

  useEffect(() => {
    getPreguntas()
      .then(({ data }) => {
        setPreguntas(data);

        data.map((value) => {
          setRespuestas((prev) => ({
            ...prev,
            [`preg${value.numero_pregunta}`]: value.id,
            [`res${value.numero_pregunta}`]: "",
          }));
        });
      })
      .catch((e) =>
        Swal.fire({
          title: "Error al obtener las preguntas",
          text: "Por favor, intente mas tarde",
          timer: 2000,
        })
      );
    return () => setPreguntas([]);
  }, []);

  return (
    <>
      {respuestas &&
        preguntas?.map((pregunta, index) => {
          return (
            <Grid item xs={6} key={pregunta.id}>
              <FormControl fullWidth sx={{ minWidth: 120, height: 45 }}>
                <InputLabel id={`${pregunta.id}-label`}>{pregunta.descripcion}</InputLabel>
                <Select
                  labelId={`${pregunta.id}-label`}
                  id={pregunta.id}
                  name={`res${pregunta.numero_pregunta}`}
                  label={pregunta.descripcion}
                  style={{ height: "100%" }}
                  {...register(`res${index}`, {
                    required: true,
                  })}
                >
                  <MenuItem value={"S"} name={`res${pregunta.numero_pregunta}`}>
                    Si
                  </MenuItem>
                  <MenuItem value={"N"} name={`res${pregunta.numero_pregunta}`}>
                    No
                  </MenuItem>
                </Select>
                {errors[`res${index}`]?.type === "required" && (
                  <MDTypography color="error">Esta pregunta es obligatoria</MDTypography>
                )}
              </FormControl>
            </Grid>
          );
        })}
      <Grid item xs={6}>
        <MDInput
          type="text"
          label="Cantidad de integrantes"
          {...register("cantidad", {
            required: true,
            min: 1,
            pattern: /^[0-9]+$/i,
          })}
        />
        {errors.cantidad?.type === "required" && (
          <MDTypography color="error">Cantidad de integrantes es obligatorio</MDTypography>
        )}
        {errors.cantidad?.type === "pattern" && (
          <MDTypography color="error">Caracteres invalidos, solo acepta numeros</MDTypography>
        )}
      </Grid>
      <Grid item xs={6}>
        <MDInput
          type="text"
          label="Latitud"
          {...register("latitud", {
            required: true,
            pattern: /^-?\d{1,2}(?:\.\d{1,7})?$/i,
          })}
        />
        {errors.latitud?.type === "required" && (
          <MDTypography color="error">Latitud es obligatorio</MDTypography>
        )}
        {errors.latitud?.type === "pattern" && (
          <MDTypography color="error">Verifique su latitud</MDTypography>
        )}
      </Grid>
      <Grid item xs={6}>
        <MDInput
          type="text"
          label="Longitud"
          {...register("longitud", {
            required: true,
            pattern: /^-?\d{1,2}(?:\.\d{1,6})?$/i,
          })}
        />
        {errors.longitud?.type === "required" && (
          <MDTypography color="error">Longitud es obligatorio</MDTypography>
        )}
        {errors.longitud?.type === "pattern" && (
          <MDTypography color="error">Verifique su longitud</MDTypography>
        )}
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth sx={{ minWidth: 120, height: 45 }}>
          <InputLabel id={`resultado-label`}>Resultado</InputLabel>
          <Select
            labelId={`resultado-label`}
            name={"resultado"}
            style={{ height: "100%" }}
            {...register("resultado", {
              required: true,
            })}
          >
            <MenuItem value={"Positivo"}>
              Positivo
            </MenuItem>
            <MenuItem value={"Negativo"}>
              Negativo
            </MenuItem>
          </Select>
          {errors.resultado?.type === "required" && (
            <MDTypography color="error">Esta pregunta es obligatoria</MDTypography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={6} >
        <MDInput
          type="text"
          label="Comentario"
          {...register("comentario")}
        />
      </Grid>
      <Grid item xs={6} >
        <MDInput
          type="text"
          label="fecha_carga"
          value={now.toISOString().slice(0, 19).replace('T', ' ')}
          {...register("fecha_carga", {
            required: true,
          })}
        />
      </Grid>
    </>
  );
};

const AdminInputs = ({ handleRole, errors, familyData, register }) => {
  const [adminRole, setAdminRole] = useState(null);

  const handleChangeRole = (e) => {
    handleRole(e.target.value);
    setAdminRole(e.target.value);
  };
  return (
    <>
      <UserPanelInputs errors={errors} register={register} familyData={familyData} />
        <Grid item xs={6}>
          <FormControl sx={{ minWidth: 120, height: 45 }}>
            <InputLabel id="demo-select-small">Rol</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={adminRole}
              label="Rol"
              onChange={handleChangeRole}
              style={{ height: "100%" }}
            >
              <MenuItem value={2}>Administrador</MenuItem>
              <MenuItem value={6}>Usuario</MenuItem>
              <MenuItem value={5}>Laboratorista</MenuItem>
              <MenuItem value={7}>Agente sanitario</MenuItem>
              <MenuItem value={8}>Salud</MenuItem>
            </Select>
          </FormControl>
          {errors.password?.type === "required" && (
            <MDTypography color="error">Rol es obligatorio</MDTypography>
          )}
        </Grid>
    </>
  );
};

const UserPanelInputs = ({ errors, register, familyData }) => {
  return (
      <Grid item xs={6}>
        <MDInput
          type="password"
          label="Contraseña"
          defaultValue={familyData?.cantidad}
          {...register("password", {
            required: true,
            min: 5,
          })}
        />
        {errors.password?.type === "required" && (
          <MDTypography color="error">Contraseña es obligatorio</MDTypography>
        )}
      </Grid>
  );
};

const FamilyInputs = ({ errors, register, familyData }) => {
  return (
    <>
      <Grid item xs={6}>
        <MDInput
          type="text"
          label="Cantidad de integrantes"
          defaultValue={familyData?.cantidad}
          {...register("cantidad", {
            required: true,
            min: 1,
            pattern: /^[0-9]+$/i,
          })}
        />
        {errors.apellido?.type === "required" && (
          <MDTypography color="error">Cantidad de integrantes es obligatorio</MDTypography>
        )}
        {errors.apellido?.type === "pattern" && (
          <MDTypography color="error">Caracteres invalidos, solo acepta numeros</MDTypography>
        )}
      </Grid>
      <Grid item xs={6}>
        <MDInput
          type="tel"
          label="Código de ovitrampa o Aspersor"
          {...register("codigo", { required: true, pattern: /^[OA]?-[0-9]+$/i })}
        />
        {errors.codigo?.type === "required" && (
          <MDTypography color="error">Codigo es obligatorio</MDTypography>
        )}
        {errors.codigo?.type === "pattern" && (
          <MDTypography color="error">
            Caracteres invalidos, para ovitrampa O-codigo numerico, para aspersor A-codigo numerico
          </MDTypography>
        )}
      </Grid>
    </>
  );
};
