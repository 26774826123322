import Swal from 'sweetalert2';


export function isAxiosError(error){
	return (error)?.isAxiosError ?? false;
}

export function responseErrorHandler(error) {
	if (isAxiosError(error)) {
		if (error.response?.status === 401) {

			localStorage.removeItem('isAuth')
            localStorage.removeItem('token')
            localStorage.removeItem('role')
			// Swal.fire({
            //     title:"El token a expirado",
            //     text:"Vuelva a iniciar sesion",
            //     icon:"error",
            //     willClose: () => {
            //         localStorage.removeItem('isAuth')
            //         localStorage.removeItem('token')
            //         localStorage.removeItem('role')

            //         window.location.replace('/authentication/sign-in')
            //     }
            // })
		}
		if (error.response?.status === 403) {
			console.error('Forbidden', error);
		}
		if (error.response?.status === 404) {
			console.error('Not Found', error);
		}
		if (error.response?.status === 500) {
			console.error('Server error', error);
		}
	}
	return Promise.reject(error);
}
