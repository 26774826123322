export default function formatResponse(value){
    switch (value) {
        case 'S':
            return 'Si'
        case 'N':
            return 'No'
    
        default:
            return value
    }
}