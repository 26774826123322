import React, { useEffect, useState } from "react";
import {
  postRegistrarUsuario,
  postRegistrarUsuarioPanel,
  postRegistraSupervisor,
  postRegisterAgent,
} from "api/users.routes";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useGetLocalidades } from "./useGetLocalidades";
import { positionsNames } from "helpers/positionsNames/positionsNames";
import { useIsSuspect } from "./useIsSuspect";
import { postRegisterCase } from "api/suspect.routes";

const IdUsuarioPanel = [1, 2, 5, 6, 8];

export const useFormRegisterUsers = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { localidades } = useGetLocalidades();
  const [localidad, setLocalidad] = useState("");
  const isSuspects = useIsSuspect();

  useEffect(() => {
    if (localidades) {
      setValue("id_localidad", localidad);
    }
  }, [localidades, localidad]);
  const onSubmit = (data) => {
     if(isSuspects){
      postRegisterCase(data).then(() =>
      Swal.fire({
        icon: "success",
        text: `Caso sospechoso creado correctamente`,
        timer: 2000,
      })
    )
    .catch((error) => {
      Swal.fire({
        icon: "error",
        text: error,
        timer: 2000,
      });
    });
    return;
     }
    if (data.id_role === 7) {
      postRegisterAgent({ ...data })
        .then(() =>
          Swal.fire({
            icon: "success",
            text: `${positionsNames.AGENTE} creado correctamente`,
            timer: 2000,
          })
        )
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: error,
            timer: 2000,
          });
        });
    }
    if (data.id_role === 3) {
      postRegistraSupervisor({ ...data })
        .then(() =>
          Swal.fire({
            icon: "success",
            text: `${positionsNames.SUPERVISOR} creado correctamente`,
            timer: 2000,
          })
        )
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: error,
            timer: 2000,
          });
        });
    } else if (IdUsuarioPanel.includes(data.id_role)) {
      postRegistrarUsuarioPanel({ ...data, role_id: data.id_role })
        .then(() =>
          Swal.fire({
            icon: "success",
            text: `${positionsNames.USUARIO} creado correctamente`,
            timer: 2000,
          })
        )
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: error,
            timer: 2000,
          });
        });
    } else if (data.id_role === 4) {
      postRegistrarUsuario({ ...data })
        .then(() =>
          Swal.fire({
            icon: "success",
            text: "Usuario creado correctamente",
            timer: 2000,
          })
        )
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: error,
            timer: 2000,
          });
        });
    }
  };

  const handleLocalidad = (e) => {
    setLocalidad(e.target.value);
  };

  const handleRole = (role) => {
    setValue("id_role", role);
  };
  return {
    localidades,
    localidad,
    register,
    handleSubmit,
    errors,
    handleLocalidad,
    onSubmit,
    handleRole,
    setValue
  };
};
